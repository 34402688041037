import React, { useMemo } from 'react';

import { ReadOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import type { DisplayValueType } from 'rc-select/lib/BaseSelect';
import { TOfferState } from 'src/api/types/paywall.types';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { capitalizeFirstCharacter } from 'src/utils/string';
import { namiLightGray, namiSecondaryBlue } from 'src/variables';
import styled from 'styled-components';

type ProductOfferMultiStatePickerProps = {
  includeTrial: boolean;
  includeIntro: boolean;
  includePromo: boolean;
};
const TooltipLinkButton = styled(Button)`
  color: ${namiLightGray};
  :hover {
    color: ${namiSecondaryBlue};
  }
  padding-left: 0px;
`;

export default function ProductOfferMultiStatePicker({
  includeTrial,
  includeIntro,
  includePromo,
}: ProductOfferMultiStatePickerProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const [
    anySkuHasIntroOffer,
    anySkuHasPromoOffer,
    anySkuHasTrialOffer,
    platforms,
    platformId,
  ] = useAppSelector(({ paywallBuilder }) => {
    return [
      paywallBuilder.anySkuHasIntroOffer,
      paywallBuilder.anySkuHasPromoOffer,
      paywallBuilder.anySkuHasTrialOffer,
      paywallBuilder.platforms,
      paywallBuilder.platformId,
    ];
  });

  const platformSupportsIntroOffers = useMemo(() => {
    const currentPlatform = platforms.find(
      (platform) => platform.id === platformId
    );
    if (currentPlatform)
      return (
        currentPlatform.type !== 'google' &&
        currentPlatform.type !== 'amazon' &&
        currentPlatform.type !== 'web'
      );
    return false;
  }, [platforms, platformId]);

  const platformSupportsPromoOffers = useMemo(() => {
    const currentPlatform = platforms.find(
      (platform) => platform.id === platformId
    );
    if (currentPlatform) return currentPlatform.type !== 'roku_pay';
    return false;
  }, [platforms, platformId]);

  const offerOptions = useMemo(() => {
    let options: Array<{
      label: string;
      title: string;
      value: TOfferState;
      disabled: boolean;
    }> = [];

    if (includeTrial) {
      options = [
        ...options,
        {
          label: 'Free Trial',
          title: 'Trial',
          value: 'trial',
          disabled: false,
        },
      ];
    }
    if (includeIntro) {
      options = [
        ...options,
        {
          label: 'Introductory Pricing',
          title: 'Intro',
          value: 'intro',
          disabled: !platformSupportsIntroOffers,
        },
      ];
    }
    if (includePromo) {
      options = [
        ...options,
        {
          label: 'Promotional Pricing',
          title: 'Promo',
          value: 'promo',
          disabled: !platformSupportsPromoOffers,
        },
      ];
    }
    return options;
  }, [
    includeIntro,
    includePromo,
    includeTrial,
    platformSupportsIntroOffers,
    platformSupportsPromoOffers,
  ]);

  return (
    <Tooltip title={getTooltipContent()} placement={'right'}>
      <Select
        options={offerOptions}
        value={pickPricingStateValue()}
        size="small"
        style={{ width: 200 }}
        dropdownMatchSelectWidth={false}
        placeholder="Preview Eligibility"
        onChange={changeOfferState}
        mode="multiple"
        maxTagCount={0}
        maxTagPlaceholder={(omittedValues) => renderPlaceholder(omittedValues)}
        allowClear
      />
    </Tooltip>
  );

  function renderPlaceholder(values: DisplayValueType[]) {
    const valuesMap = values.reduce((output, value) => {
      return [...output, capitalizeFirstCharacter(value.value as string)];
    }, [] as Array<string>);
    return <>{valuesMap.join(' & ')} Offer</>;
  }

  function getTooltipContent(): JSX.Element {
    return (
      <>
        {`Pick ${
          includePromo || includeIntro ? 'offer' : 'trial'
        } eligibility to preview`}
        <TooltipLinkButton
          type="link"
          href="https://learn.namiml.com/public-docs/no-code-paywalls/pricing-eligibility-states"
          target="_blank"
          size="small"
          icon={<ReadOutlined />}
        >
          Learn more
        </TooltipLinkButton>
      </>
    );
  }

  function changeOfferState(offer: TOfferState[]) {
    actions.setAnySkuHasTrialOffer(offer.includes('trial'));
    actions.setAnySkuHasIntroOffer(offer.includes('intro'));
    actions.setAnySkuHasPromoOffer(offer.includes('promo'));
  }

  function pickPricingStateValue(): TOfferState[] {
    let result: TOfferState[] = [];
    if (anySkuHasTrialOffer) result = [...result, 'trial'];
    if (anySkuHasIntroOffer) result = [...result, 'intro'];
    if (anySkuHasPromoOffer) result = [...result, 'promo'];
    return result;
  }
}
