import React from 'react';

import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import styled from 'styled-components';

import IntegrationCard from '../../../components/Cards/IntegrationCard/IntegrationCard';
import Page from '../../../components/Page/Page';
import { useIntegrationsMetadataQuery } from '../../../hooks/queries/integration.hooks';
import { TIntegrationParams } from './params.types';
import PlatformForm from './PlatformForm';
import StreamForm from './StreamForm';

const PLATFORM_TYPES = new Set([
  'google',
  'apple',
  'amazon_iap',
  'roku_pay',
  'web',
]);

const FormCol = styled(Col)`
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: white;
  padding: 2rem 2rem 0 2rem !important;
  border-radius: 2px;
`;

export default function StreamPlatformIntegrationDetailPage() {
  const params = useParams<TIntegrationParams>();
  const metadataQuery = useIntegrationsMetadataQuery();

  const integration = metadataQuery.data?.find(
    ({ type }) => type === params.type
  );

  const pageTitle = integration?.title || 'Loading integration';
  return (
    <Page title={pageTitle}>
      <Row gutter={[16, 16]}>
        <Col sm={24}>
          <Breadcrumb
            items={[
              { name: 'Integrations', url: '/integrations/' },
              { name: pageTitle },
            ]}
          />
        </Col>
        <Col sm={24} md={8} lg={6}>
          {integration && (
            <IntegrationCard
              title={integration.title}
              subtitle={integration.subtitle}
              logo={integration.logo}
              $overflow={false}
              resources={integration.references}
              status={!integration.implemented ? 'Coming Soon' : ''}
            />
          )}
        </Col>
        <FormCol sm={24} md={16} lg={18}>
          {PLATFORM_TYPES.has(params.type) ? <PlatformForm /> : <StreamForm />}
        </FormCol>
      </Row>
    </Page>
  );
}
