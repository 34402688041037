import React from 'react';

import { Space, Tooltip } from 'antd';
import { TAllComponentDetailValues } from 'src/api/types/paywallTemplate.types';
import { useActions } from 'src/hooks/redux.hooks';
import { allNewComponentOptions } from 'src/pages/admin/paywalls/PaywallBuilder/editor/AddComponentPopover';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { namiBrightBlue, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

type HoverTagProps = {
  title?: string;
  children: JSX.Element | JSX.Element[] | null;
  namiComponentType?: TAllComponentDetailValues;
  id: string | undefined;
};

const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-open {
    outline: 1.5px solid ${namiBrightBlue};
    outline-offset: 0px;
  }
`;
const StyledSpace = styled(Space)`
  * > svg {
    height: 11px !important;
    width: 11px !important;
    transform: translateY(2px);
  }

  :hover {
    text-decoration: underline;
  }
`;
export default function HoverTag({
  title,
  children,
  namiComponentType,
  id,
}: HoverTagProps) {
  const actions = useActions(PaywallBuilderSlice.actions);

  return (
    <StyledTooltip
      title={getTitle()}
      arrowPointAtCenter={false}
      placement="topLeft"
      showArrow={false}
      align={{ offset: [-1, 14] }}
      destroyTooltipOnHide
      overlayInnerStyle={{
        minHeight: 'unset',
        backgroundColor: namiBrightBlue,
        boxShadow: 'none',
        height: 'fit-content',
        padding: '2px 6px',
        fontSize: 12,
        fontWeight: 500,
        color: namiPureWhite,
        borderRadius: '4px 4px 0px 0px',
        cursor: 'pointer',
      }}
    >
      {children}
    </StyledTooltip>
  );

  function getTitle(): JSX.Element | undefined {
    if (namiComponentType && id) {
      return (
        <StyledSpace
          direction="horizontal"
          size={3}
          onClick={
            id
              ? () => {
                  actions.setEditingComponentId(id);
                  actions.addToExpandedTreeKeys(id);
                  actions.setSelectedTreeKey(id);
                  actions.setEditingSlideId(null);
                }
              : undefined
          }
        >
          {generateComponentIcon()}
          {title}
        </StyledSpace>
      );
    }
    return undefined;
  }

  function generateComponentIcon(): JSX.Element {
    if (
      namiComponentType &&
      allNewComponentOptions.hasOwnProperty(namiComponentType)
    ) {
      return allNewComponentOptions[namiComponentType].icon;
    }
    return <></>;
  }
}
