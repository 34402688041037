export const config = {
  squareSize: 294,
  barSize: 18,
  crossSize: 18,
  inputSize: 40,
  delay: 150,
  defaultColor: 'rgba(19, 116, 222, 1)',
  defaultGradient:
    'linear-gradient(180deg, rgba(239,244,247,1) 0%, rgba(221,221,221,1) 35%, rgba(76,84,90,1) 100%)',
} as const;
