import React from 'react';

import { PreviewDeviceMeta } from 'src/api/types/paywall.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import styled, { css } from 'styled-components';

import { colorBlack, colorNegative } from '../../variables';

type IPhoneProps = {
  className?: string;
  children: JSX.Element | JSX.Element[];
  inFocusedState: boolean; //Unused
  scale?: number;
};

const Wrapper = styled.div<{ padding: number; radius: number; scale?: number }>`
  height: fit-content;
  width: fit-content;
  background: linear-gradient(193deg, #474746 1.02%, #666665 97.6%);
  background-color: #787876;
  border-radius: ${({ radius }) => radius}px;
  padding: ${({ padding }) => padding}px;
  margin: 0 auto 0 auto;
  z-index: 2;
  box-shadow: 0 35px 60px -24px rgba(0 0 0 / 0.4);
  ${({ scale }) => (scale ? `transform: scale(${scale});` : '')}

  &::before,
  &::after {
    content: '';
    height: 4px;
    width: 100%;
    background-color: rgba(${colorBlack}, 0.25);
    position: absolute;
    left: 0;
  }

  &::before {
    top: 50px;
  }

  &::after {
    bottom: 50px;
  }
`;

const Body = styled.div<{ radius: number; padding: number }>`
  border-radius: ${({ radius }) => radius}px;
  width: fit-content;
  height: fit-content;
  background-color: ${colorBlack};
  position: relative;
  padding: ${({ padding }) => padding}px;
  z-index: 2;
  cursor: pointer;
`;

const PhoneView = styled.div<{ radius: number }>`
  width: fit-content;
  height: fit-content;
  background-color: ${colorNegative};
  border-radius: ${({ radius }) => radius}px;
  position: relative;
  overflow: hidden;
`;

const Notch = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 30px;
  width: 45%;
  background-color: ${colorBlack};
  border-radius: 0 0 18px 18px;
  z-index: 3;
  box-shadow: 0px 4px 12px 0px rgba(114, 114, 114, 0.12);
`;

const DynamicIsland = styled.div`
  position: absolute;
  left: 50%;
  top: 6px;
  transform: translateX(-50%);
  height: 30px;
  width: 33%;
  background-color: ${colorBlack};
  border-radius: 30px;
  z-index: 3;
  box-shadow: 0px 4px 12px 0px rgba(114, 114, 114, 0.12);
`;

const AndroidStatusBar = styled.div<{
  height: number;
  brand?: 'samsung' | 'google';
}>`
  height: ${({ height }) => height}px;
  width: 100%;
  background-color: ${colorBlack};
  z-index: 2;
`;

const AndroidFooterBar = styled.div<{
  height: number;
  brand: 'samsung' | 'google';
}>`
  height: ${({ height }) => height}px;
  width: 100%;
  position: inherit;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ brand }) => {
    if (brand === 'samsung') {
      return css`
        background-color: #f6f6f6;
      `;
    }
    return css`
      background-color: ${colorBlack};
    `;
  }}
`;

const GoogleFooterIcon = styled.div<{ height: number }>`
  height: 25%;
  border-radius: ${({ height }) => height}px;
  width: 30%;
  background-color: #b2b2b2;
  margin: auto;
`;

const SamsungFooterIcon = styled.div`
  border-radius: 6px;
  border: 2px solid #8f8f8f;
  width: 17px;
  height: 17px;
  margin: auto;
`;

const PinholeCamera = styled.div<{
  height: number;
  position: 'center' | 'left';
}>`
  position: absolute;
  height: ${({ height }) => height}px;
  width: ${({ height }) => height}px;
  --height: ${({ height }) => height}px;
  --half: calc(var(--height) * 0.5);
  border-radius: ${({ height }) => height}px;
  border: 3px solid ${colorNegative};
  top: var(--half);
  background-color: ${colorBlack};
  ${({ position }) => {
    if (position === 'center') {
      return css`
        right: 50%;
      `;
    } else {
      return css`
        left: 5%;
      `;
    }
  }}
`;

const PhoneInner = styled.div<{ height: number; width: number }>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  justify-content: initial;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function IPhone({ children, scale, ...props }: IPhoneProps) {
  const { currentDevice } = useAppSelector(({ paywallBuilder }) => ({
    currentDevice: paywallBuilder.previewDevice,
  }));
  const currentDeviceMeta = PreviewDeviceMeta[currentDevice];
  const viewportHeight =
    currentDeviceMeta.viewportHeight -
    (currentDeviceMeta.builtInHeaderHeight || 0) -
    (currentDeviceMeta.bottomBarHeight || 0);

  return (
    <Wrapper
      padding={currentDeviceMeta.outerPadding}
      radius={
        currentDeviceMeta.innerRadius +
        currentDeviceMeta.middlePadding +
        currentDeviceMeta.outerPadding
      }
      scale={scale}
      {...props}
    >
      <Body
        className="namipreviewer iphone"
        padding={currentDeviceMeta.middlePadding}
        radius={currentDeviceMeta.innerRadius + currentDeviceMeta.middlePadding}
      >
        <PhoneView radius={currentDeviceMeta.innerRadius}>
          {currentDeviceMeta.hasNotch && <Notch />}
          {currentDeviceMeta.hasDynamicIsland && <DynamicIsland />}
          {currentDeviceMeta.os === 'android' && (
            <AndroidStatusBar
              height={currentDeviceMeta.builtInHeaderHeight || 30}
            >
              {currentDeviceMeta.pinholeCameraPosition && (
                <PinholeCamera
                  height={(currentDeviceMeta.statusBarHeight || 30) / 2.5}
                  position={currentDeviceMeta.pinholeCameraPosition}
                />
              )}
            </AndroidStatusBar>
          )}
          <PhoneInner
            height={viewportHeight}
            width={currentDeviceMeta.viewportWidth}
          >
            {children}
          </PhoneInner>
          {currentDeviceMeta.os === 'android' &&
            currentDeviceMeta.bottomBarHeight && (
              <AndroidFooterBar
                height={currentDeviceMeta.bottomBarHeight}
                brand={currentDeviceMeta.brand || 'google'}
              >
                {currentDeviceMeta.brand === 'samsung' ? (
                  <SamsungFooterIcon />
                ) : (
                  <GoogleFooterIcon
                    height={currentDeviceMeta.bottomBarHeight}
                  />
                )}
              </AndroidFooterBar>
            )}
        </PhoneView>
      </Body>
    </Wrapper>
  );
}
