import React from 'react';

import { Col, Collapse, Row } from 'antd';
import styled from 'styled-components';

import {
  FULL_SIZE_FIELDS,
  TComponent,
  TFormSection,
} from '../../../../../api/types/paywallTemplate.types';
import { testObjectMatches } from '../../../../../components/PaywallPreview/utils';
import { useAppSelector } from '../../../../../hooks/redux.hooks';
import { toSlug } from '../../../../../utils/string';
import { getAttr } from '../../utils/functions';
import { DisplayFieldSwitch } from './inputs/DisplayFieldSwitch';
import FullscreenToggle from './inputs/FullscreenToggle';
import ProductGroupsFormItem from './inputs/ProductGroupsFormItem';
import ProductPricingFormItem from './inputs/ProductPricingFormItem';
import TrialEligiblityToggle from './inputs/TrialEligiblityToggle';
import SmartInput from './SmartInput';

type TFormSectionProps = TFormSection & {
  variables: Record<string, any>;
  isSingle: boolean;
  onChange: (variable: string, value: any) => void;
  component?: TComponent;
  collapseIntercomPrefix: string;
  extraContentBelowHeader?: React.ReactNode;
  extraContentAtBottom?: React.ReactNode;
  hideCollapseIcon?: boolean;
  extraCollapseHeaderContent?: React.ReactNode;
  currentAssertionIndex?: number;
};

export const FieldCollapseWrapper = styled(Collapse)`
  width: 100%;

  .ant-collapse-header {
    background: white;
    font-weight: 500;
    font-size: 13px;
    padding: 6px !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 5px 6px !important;
  }
`;

export function FormSection({
  variables,
  fields,
  hint,
  collapsible,
  isSingle,
  title,
  displayVar,
  conditions,
  orConditions,
  onChange,
  collapseIntercomPrefix,
  extraContentBelowHeader,
  extraContentAtBottom,
  hideCollapseIcon,
  extraCollapseHeaderContent,
  currentAssertionIndex,
  component,
}: TFormSectionProps) {
  const inDarkMode = useAppSelector((state) => state.paywallBuilder.inDarkMode);
  const shouldDisplay =
    displayVar === null || !!getAttr(variables, ...displayVar.split('.'));
  const switcher = displayVar ? (
    <DisplayFieldSwitch
      key={displayVar}
      hint={hint || undefined}
      label={title}
      checked={shouldDisplay}
      onChange={(value) => onChange(displayVar, value)}
      withColumn
    />
  ) : null;
  if (conditions) {
    if (!conditions.every(testObjectMatches)) return null;
  } else if (orConditions) {
    if (!orConditions.some(testObjectMatches)) return null;
  }

  const header: React.ReactNode = title;

  const children = [
    switcher,
    (shouldDisplay ? fields : []).map((field) => {
      if (field === 'productGroupSelector') {
        return (
          <Col xs={24} key={field}>
            <ProductGroupsFormItem />
          </Col>
        );
      }
      if (
        field === 'productPricingStateSelector' ||
        field === 'productTrialStateSelector'
      ) {
        return (
          <Col xs={24} key={field}>
            <ProductPricingFormItem
              includeIntroPromo={field === 'productPricingStateSelector'}
            />
          </Col>
        );
      }
      if (field === 'fullscreenToggle') {
        return (
          <Col xs={24} key={field}>
            <FullscreenToggle />
          </Col>
        );
      }
      if (field === 'trialEligibilityToggle') {
        return (
          <Col xs={24} key={field}>
            <TrialEligiblityToggle />
          </Col>
        );
      }
      if (['undefined', 'string'].includes(typeof field)) return null;
      const conditions = field.conditions || [];
      if (conditions.length > 0 && !conditions.every(testObjectMatches)) {
        return null;
      }
      const size = FULL_SIZE_FIELDS.has(field.type) ? 24 : 12;
      const variable = (inDarkMode && field.darkModeVariable) || field.variable;
      return (
        <Col xs={24} md={size} key={field.variable}>
          <SmartInput
            label={title}
            name={field.label}
            variable={variable}
            isSingle={isSingle}
            collapsible={collapsible}
            value={getAttr(variables, ...variable.split('.'))}
            onChange={(value: any) => onChange(variable, value)}
            controlled={true}
            editable={!field.readOnly}
            aspectRatio={field.aspectRatio || null}
            type={field.type}
            hint={field.hint || null}
            markdownHint={field.markdownHint || false}
            placeholder={field.placeholder || null}
            description={field.description || null}
            options={field.options || null}
            maxLimit={field.maxLimit || null}
            minLimit={field.minLimit || null}
            newRow={field.newRow}
            carousel={field.carousel || null}
            darkModeVariable={field.darkModeVariable || null}
            showSmartTextCloud={
              field.showSmartTextCloud || !variable.includes('var.')
            }
            showVariableCloud={field.showVariableCloud}
            showOpacity={!!field.showOpacity}
            availableListComponents={field.availableListComponents || null}
            componentLocation={field.componentLocation || undefined}
            currentAssertionIndex={currentAssertionIndex}
            parentDataSourceRoot={field.parentDataSourceRoot || null}
            component={component}
          />
        </Col>
      );
    }),
  ];

  if (!collapsible) return <>{children}</>;
  return (
    <FieldCollapseWrapper
      defaultActiveKey={title}
      expandIconPosition="end"
      ghost
      className={
        collapseIntercomPrefix + '-' + toSlug(title || 'componentGroup')
      }
    >
      <Collapse.Panel
        header={header}
        key={title}
        extra={extraCollapseHeaderContent}
        showArrow={!hideCollapseIcon}
      >
        <Row gutter={10}>
          {extraContentBelowHeader}
          {children}
          {extraContentAtBottom}
        </Row>
      </Collapse.Panel>
    </FieldCollapseWrapper>
  );
}
