import React from 'react';

import { RouteOutlined } from '@mui/icons-material';
import { Button, Tooltip } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

import type { RootState } from '../../../../redux';

export default function PaywallFlowPicker() {
  const inFlowState = useAppSelector(
    ({ paywallBuilder: { flowState } }: RootState) => flowState
  );
  const actions = useActions(PaywallBuilderSlice.actions);

  return (
    <>
      <Tooltip
        title="Preview this paywall inside a multi-screen campaign flow"
        mouseEnterDelay={0.8}
      >
        <Button
          type={inFlowState ? 'primary' : 'ghost'}
          icon={<RouteOutlined style={{ transform: 'translateY(3px)' }} />}
          size="small"
          onClick={toggleFlowState}
        />
      </Tooltip>
    </>
  );

  function toggleFlowState() {
    actions.setFlowState(!inFlowState);
  }
}
