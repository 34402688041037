import React from 'react';

import { Tooltip } from 'antd';
import { formatValueForColorPicker } from 'src/pages/admin/paywalls/PaywallBuilder/editor/inputs/GradientPicker';
import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';

interface ColorSquareProps {
  color: string;
  colorIsGradient?: boolean;
  onClick?: () => void;
}

const ColorSquareFull = styled.div<{ color: string; onClickExists: boolean }>`
  ${({ color, onClickExists }) => {
    return css`
      height: 21px;
      width: 21px;
      border-radius: 3px;
      border: 0.5px solid #ebebeb;
      display: flex;
      flex-direction: row;
      background: ${color};
      cursor: ${onClickExists ? 'pointer' : 'default'};
    `;
  }}
`;

const ColorSquareHalf = styled.div<{ color: string }>`
  ${({ color }) => {
    return css`
      background: ${color};
      width: 50%;
      height: 100%;
    `;
  }}
`;

const ColorSquareWrapper = styled.div<{ onClickExists: boolean }>`
  ${({ onClickExists }) => {
    return css`
      height: 21px;
      width: 21px;
      border-radius: 3px;
      border: 0.5px solid #ebebeb;
      display: flex;
      flex-direction: row;
      cursor: ${onClickExists ? 'pointer' : 'default'};
      background: repeating-conic-gradient(#d1d1d1 0% 25%, transparent 0% 50%)
        50% / 10px 10px;
    `;
  }}
`;

export default function ColorSquare({
  color,
  colorIsGradient,
  onClick,
}: ColorSquareProps) {
  let tinyColor = tinycolor(formatValueForColorPicker(color));
  const colorMinusOpacity = tinyColor.toHexString().toUpperCase();

  if (colorIsGradient) {
    return (
      <ColorSquareFull color={color} onClickExists={onClick !== undefined} />
    );
  }
  const opacityString =
    tinyColor.getAlpha() < 1 ? `${tinyColor.getAlpha() * 100}%` : '';

  return (
    <Tooltip title={`#${tinyColor.toHex().toUpperCase()} ${opacityString}`}>
      <ColorSquareWrapper
        onClick={onClick ? onClick : undefined}
        onClickExists={onClick !== undefined}
      >
        <ColorSquareHalf color={colorMinusOpacity} />
        <ColorSquareHalf color={color} />
      </ColorSquareWrapper>
    </Tooltip>
  );
}
