import React from 'react';

import { DatePicker, Form, Space, Tag } from 'antd';
import moment, { Moment } from 'moment';
import { useAppContext } from 'src/hooks';
import styled from 'styled-components';

const StyledTag = styled(Tag)`
  cursor: pointer;
`;

type CampaignSchedulerProps = {
  startDateInPast: boolean;
  startDate: string | null;
  expireDate: string | null;
  expireDateInPast: boolean;
  onChangeStartDate: (value: Moment | null) => void;
  onChangeExpireDate: (value: Moment | null) => void;
};

export default function CampaignScheduler({
  startDateInPast,
  startDate,
  expireDate,
  expireDateInPast,
  onChangeStartDate,
  onChangeExpireDate,
}: CampaignSchedulerProps) {
  const { userHasEntitlement } = useAppContext();
  const userCanEditCampaign = userHasEntitlement('app.campaign.update');
  return (
    <Space direction="horizontal" size="large">
      <Form.Item
        label="Start Date"
        className="intercom-campaignStart"
        validateStatus={startDateInPast ? 'error' : undefined}
      >
        <DatePicker
          showNow={false}
          onChange={onChangeStartDate}
          placeholder="Select date"
          value={startDate ? moment(startDate) : undefined}
          showTime={{
            showNow: false,
            format: 'HH:mm',
            defaultValue: moment('00:00:00', 'HH:mm'),
          }}
          format={'YYYY-MM-DD HH:mm'}
          disabledDate={(currentDate) => currentDate.isBefore(moment(), 'day')}
          disabled={!userCanEditCampaign}
          renderExtraFooter={startRangeFooter}
        />
      </Form.Item>
      <Form.Item
        label="End Date"
        className="intercom-campaignExpire"
        validateStatus={expireDateInPast ? 'error' : undefined}
      >
        <DatePicker
          showNow={false}
          onChange={onChangeExpireDate}
          placeholder="Select date"
          value={expireDate ? moment(expireDate) : undefined}
          showTime={{
            showNow: false,
            format: 'HH:mm',
            defaultValue: moment('00:00:00', 'HH:mm'),
          }}
          format={'YYYY-MM-DD HH:mm'}
          disabledDate={(currentDate) => currentDate.isBefore(moment(), 'day')}
          disabled={!userCanEditCampaign}
          renderExtraFooter={expireRangeFooter}
        />
      </Form.Item>
    </Space>
  );

  function startRangeFooter() {
    return (
      <Space direction="horizontal" size={2}>
        <StyledTag
          color="blue"
          onClick={() => onChangeStartDate(moment().add(12, 'hour'))}
        >
          In 12 hours
        </StyledTag>
        <StyledTag
          color="blue"
          onClick={() =>
            onChangeStartDate(moment().add(1, 'day').startOf('day'))
          }
        >
          Tomorrow
        </StyledTag>
        <StyledTag
          color="blue"
          onClick={() =>
            onChangeStartDate(moment().add(1, 'week').startOf('isoWeek'))
          }
        >
          Next week
        </StyledTag>
        <StyledTag
          color="blue"
          onClick={() =>
            onChangeStartDate(moment().add(1, 'month').startOf('month'))
          }
        >
          Next month
        </StyledTag>
      </Space>
    );
  }

  function expireRangeFooter() {
    return (
      <Space direction="horizontal" size={2}>
        <StyledTag
          color="blue"
          onClick={() => onChangeExpireDate(moment().add(12, 'hour'))}
        >
          In 12 hours
        </StyledTag>
        <StyledTag
          color="blue"
          onClick={() =>
            onChangeExpireDate(moment().add(1, 'day').startOf('day'))
          }
        >
          Tomorrow
        </StyledTag>
        <StyledTag
          color="blue"
          onClick={() =>
            onChangeExpireDate(moment().add(1, 'week').startOf('isoWeek'))
          }
        >
          Next week
        </StyledTag>
        <StyledTag
          color="blue"
          onClick={() =>
            onChangeExpireDate(moment().add(1, 'month').startOf('month'))
          }
        >
          Next month
        </StyledTag>
      </Space>
    );
  }
}
