import React, { useMemo } from 'react';

import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import { Col, Row, Tag } from 'antd';
import {
  TAssertionMapToReadable,
  TCollapseContainer,
  TComponent,
  TComponentLocation,
  TConditionalComponent,
  TProductVariable,
  TRepeatingList,
  TTestObject,
  TTestObjectOperatorNameMap,
} from 'src/api/types/paywallTemplate.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { useBooleanState } from 'src/hooks';
import { useEntitlementRefIdMap } from 'src/hooks/queries/entitlement.hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import {
  deconstructVariable,
  getCustomVariableFromLaunchContext,
  getCustomVariableFromLaunchObject,
  getCustomVariableFromPersonalizationToken,
  getProductGroupIndexFromId,
  parseOperatorFromCustomLaunchObject,
} from 'src/utils/paywall';

import {
  TAssertionType,
  TConditionEditorType,
} from '../../../utils/componentGeneration';
import ConditionsEditor from '../../modals/ConditionsEditor';

type ConditionSelectorProps = {
  component: TConditionalComponent | TRepeatingList;
  componentLocation: TComponentLocation | null;
  variant: TConditionEditorType;
  assertionsGroup: TTestObject[];
  assertionIndex: number;
  productChild: boolean;
  childOfCollapseComponent?: TCollapseContainer;
  parentRepeatingGrid: TComponent | null;
  type: TAssertionType;
};

const selector = createSelector(
  [
    ({ paywallBuilder: { paywall } }: RootState) => paywall!.template,
    ({ paywallBuilder: { productGroups } }: RootState) => productGroups,
  ],
  (template, productGroups) => ({
    templateCapabilities: template['ui.capabilities'],
    productGroups: productGroups,
    currentProductVariables:
      template['ui.productSettings']?.variablesList || [],
  })
);

export default function ConditionSelector({
  component,
  componentLocation,
  variant,
  assertionsGroup,
  assertionIndex,
  productChild,
  childOfCollapseComponent,
  parentRepeatingGrid,
  type,
}: ConditionSelectorProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const entitlementIdMap = useEntitlementRefIdMap();
  const { templateCapabilities, productGroups, currentProductVariables } =
    useAppSelector(selector);

  const [conditionEditorOpen, openConditionEditor, closeConditionEditor] =
    useBooleanState(false);

  const assertionsToUse: TTestObject[] = useMemo(() => {
    if (determineIfLoopSource(component))
      return (component as TRepeatingList).loopSourceConditions || [];
    if (variant === 'conditionAttributes') return assertionsGroup;
    if (type === 'or' && component.hasOwnProperty('orAssertions'))
      return component.orAssertions || [];
    return component.assertions || [];
  }, [variant, component, assertionsGroup, type]);

  const productVariableMap = useMemo(() => {
    return currentProductVariables.reduce((output, variable) => {
      return {
        ...output,
        [variable.variable]: variable,
      };
    }, {} as { [key: string]: TProductVariable });
  }, [currentProductVariables]);

  const children: React.ReactNode[] = useMemo(() => {
    if (!assertionsToUse.length) {
      return [
        <Tag
          style={{
            background: '#ffffff',
            borderStyle: 'dashed',
            userSelect: 'none',
            borderRadius: 4,
          }}
          key="emptyAssertions"
        >
          {variant === 'loopSource' ? 'All' : 'Always'}
        </Tag>,
      ];
    } else {
      return assertionsToUse.map((value, index) => {
        return (
          <span key={index}>
            <Tag style={{ borderRadius: 4 }} key={`tag${index}`}>
              {getConditionDescription(value)}
            </Tag>
            {assertionsToUse.length > 1 && index < assertionsToUse.length - 1 && (
              <span style={{ marginRight: 6 }} key={`join${index}`}>
                {type}
              </span>
            )}
          </span>
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assertionsToUse, type]);

  return (
    <>
      <Col xs={24} md={24} key={'conditions'} style={{ marginBottom: 16 }}>
        <Row gutter={[0, 10]} style={{ width: '100%', marginBottom: 6 }}>
          <span>
            {variant === 'conditionAttributes'
              ? 'When:'
              : variant === 'loopSource'
              ? 'Filter data to:'
              : 'Show component if:'}
          </span>
        </Row>
        <Row gutter={[0, 10]} style={{ width: '100%' }} wrap>
          {children}
          <IconActionButton
            type="text"
            size="small"
            icon={<EditOutlined style={{ fontSize: '13px' }} />}
            onClick={openConditionEditor}
          >
            Edit Rules
          </IconActionButton>
          {variant === 'loopSource' &&
            componentLocation &&
            assertionsToUse.length > 0 && (
              <IconActionButton
                type="text"
                size="small"
                icon={<CloseOutlined style={{ fontSize: '13px' }} />}
                onClick={() =>
                  actions.updateComponentLoopSourceConditions({
                    idLocation: componentLocation,
                    conditionals: [],
                  })
                }
              >
                Remove Rules
              </IconActionButton>
            )}
        </Row>
      </Col>
      <ConditionsEditor
        onCancel={closeConditionEditor}
        onFinish={onEditConditions}
        open={conditionEditorOpen}
        templateCapabilities={templateCapabilities || []}
        componentAssertions={assertionsToUse}
        assertionIndex={0} //Not used
        productChild={productChild}
        childOfCollapseComponent={childOfCollapseComponent}
        parentRepeatingGrid={parentRepeatingGrid}
        type={type}
        variant={variant}
      />
    </>
  );

  function getConditionDescription(assertion: TTestObject): string {
    const parsedAssertion = `${assertion.value} ${assertion.operator} ${assertion.expected}`;
    if (TAssertionMapToReadable.hasOwnProperty(parsedAssertion)) {
      return TAssertionMapToReadable[parsedAssertion];
    } else if (assertion.expected === `\${state.currentGroupId}`) {
      const productGroupIndexRegexMatch = getProductGroupIndexFromId(
        assertion.value
      );

      if (productGroups.length >= productGroupIndexRegexMatch) {
        return `Product Group equals ${productGroups[productGroupIndexRegexMatch].display_name}`;
      }
      return `Product Group equals ${assertion.value}`;
    } else if (assertion.value === `\${launch.productGroups}`) {
      const productGroupIndexRegexMatch = getProductGroupIndexFromId(
        assertion.expected
      );

      if (productGroups.length >= productGroupIndexRegexMatch) {
        return `Launch Group is ${productGroups[productGroupIndexRegexMatch].display_name}`;
      }
    } else if (assertion.value.includes('block')) {
      const customVariable = getCustomVariableFromLaunchObject(
        assertion.value,
        true
      );
      const operator = parseOperatorFromCustomLaunchObject(
        assertion.operator,
        assertion.expected
      );

      if (customVariable && variant === 'loopSource')
        return `'${customVariable}' ${TTestObjectOperatorNameMap[operator]}`;

      if (customVariable)
        return `Current Cell '${customVariable}' ${TTestObjectOperatorNameMap[operator]}`;
    } else if (assertion.value.includes('customer.')) {
      const customVariable = getCustomVariableFromPersonalizationToken(
        assertion.value
      );

      if (customVariable)
        return `Personalization Token '${customVariable}' ${
          assertion.operator === 'set' ? 'is set' : 'is not set'
        }`;
    } else if (assertion.value.includes('launch.customObject')) {
      const customVariable = getCustomVariableFromLaunchObject(
        assertion.value,
        false
      );
      const operator = parseOperatorFromCustomLaunchObject(
        assertion.operator,
        assertion.expected
      );

      if (customVariable)
        return `Custom Data '${customVariable}' ${TTestObjectOperatorNameMap[operator]}`;
    } else if (assertion.value.includes('launch.customAttributes')) {
      const customVariable = getCustomVariableFromLaunchContext(
        assertion.value
      );

      if (customVariable)
        return `Custom Variable '${customVariable}' ${
          assertion.operator === 'set' ? 'is set' : 'is not set'
        }`;
    } else if (assertion.value === `\${sku.entitlements}`) {
      const entitlement = entitlementIdMap[assertion.expected];
      const operatorLabel = TTestObjectOperatorNameMap[assertion.operator];
      return `Product Entitlements ${operatorLabel} ${
        entitlement ? entitlement.name : assertion.expected
      }`;
    } else if (assertion.value.includes('sku.')) {
      const operator = parseOperatorFromCustomLaunchObject(
        assertion.operator,
        assertion.expected
      );
      const variableValue = deconstructVariable(assertion.value, false, true);
      if (productVariableMap.hasOwnProperty(variableValue)) {
        const variableName = productVariableMap[variableValue].display_name;
        return `Product Variable ${variableName || ''} ${
          TTestObjectOperatorNameMap[operator]
        }`;
      }
      return `Product Variable ${assertion.value} ${TTestObjectOperatorNameMap[operator]}`;
    } else if (assertion.value.includes('state.openHeaderIds')) {
      return `Collapse is Open`;
    }
    return parsedAssertion;
  }

  function onEditConditions(
    newAssertions: TTestObject[],
    _index: number,
    type: TAssertionType
  ) {
    if (!componentLocation) {
      console.warn(
        `Missing component location for ${component.id}, unable to edit assertions`
      );
      return;
    }
    if (variant === 'conditionAttributes') {
      actions.updateComponentConditionAttributeAssertions({
        idLocation: componentLocation,
        conditionals: newAssertions,
        groupIndex: assertionIndex,
        joinType: type,
      });
    } else if (variant === 'loopSource') {
      actions.updateComponentLoopSourceConditions({
        idLocation: componentLocation,
        conditionals: newAssertions,
      });
    } else {
      actions.updateComponentConditionals({
        idLocation: componentLocation,
        conditionals: newAssertions,
        joinType: type,
      });
    }
  }

  function determineIfLoopSource(
    tbd: TConditionalComponent | TRepeatingList
  ): tbd is TRepeatingList {
    if ((tbd as TRepeatingList).loopSourceConditions) {
      return true;
    }
    return false;
  }
}
