import React from 'react';

import { TPreviewDeviceMeta } from 'src/api/types/paywall.types';
import { TSemverObj } from 'src/utils/parsing';
import styled, { css } from 'styled-components';

import { TContainer } from '../../../api/types/paywallTemplate.types';
import {
  alignAndJustifyItems,
  paddingAndMargin,
  pickAndApplyBackgroundColor,
  transition,
} from '../css';
import TemplateComponent from '../TemplateComponent';
import { withOverrides } from '../utils';

type BackgroundContainerProps = {
  component: TContainer;
  deviceMeta: TPreviewDeviceMeta;
  zIndex: number;
  fullscreen?: boolean;
  groupId: string | null;
  minSDKVersion: TSemverObj;
};

const Wrapper = styled.div<{
  component: TContainer;
  deviceMeta: TPreviewDeviceMeta;
  zIndex: number;
  fullscreen?: boolean;
}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  width: 100%;
  bottom: -1px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${({ component, deviceMeta, zIndex, fullscreen }) => css`
    z-index: ${zIndex};
    ${applyBackgroundTop(deviceMeta, fullscreen)}
    ${pickAndApplyBackgroundColor(component)}
    ${transition()}
    ${paddingAndMargin(component)}
    ${alignAndJustifyItems(component)}
  `}
`;

export default function BackgroundContainer({
  component,
  deviceMeta,
  zIndex,
  fullscreen,
  groupId,
  minSDKVersion,
}: BackgroundContainerProps) {
  return (
    <Wrapper
      component={withOverrides(component)}
      deviceMeta={deviceMeta}
      zIndex={zIndex}
      fullscreen={fullscreen}
    >
      {(component.components || []).map((child, i) => (
        <TemplateComponent
          key={i}
          component={child}
          groupId={groupId}
          minSDKVersion={minSDKVersion}
        />
      ))}
    </Wrapper>
  );
}

function applyBackgroundTop(
  deviceMeta: TPreviewDeviceMeta,
  fullscreen?: boolean
) {
  if (deviceMeta.type === 'phone') {
    if (deviceMeta.os === 'android') {
      return css`
        border-radius: 0px;
        top: ${deviceMeta.builtInHeaderHeight || 0}px;
      `;
    }
    if (!fullscreen) {
      return css`
        border-radius: 30px;
        top: 40px;
      `;
    }
  }
  return css`
    border-radius: 0px;
    top: 0px;
  `;
}
