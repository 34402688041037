import {
  TPaywallMedia,
  TProductGroup,
} from '../../../../api/types/paywall.types';
import { TPaywallTemplate } from '../../../../api/types/paywallTemplate.types';

type TMediaVarOptions = { convertToUrl?: boolean };
type TGroupReplacement = { id: string; displayName: string };

export function buildMediaVariables(
  mediaList: { [mediaName: string]: Pick<TPaywallMedia, 'content'> },
  { convertToUrl }: TMediaVarOptions = { convertToUrl: false }
): { [mediaName: string]: string | Blob | null } {
  return Object.entries(mediaList).reduce((output, [name, { content }]) => {
    if (convertToUrl && typeof content !== 'string' && !!content) {
      content = URL.createObjectURL(content);
    }
    return { ...output, [name]: content };
  }, {});
}

export function buildStateGroups(
  template: TPaywallTemplate,
  productGroups: TProductGroup[]
): TGroupReplacement[] {
  const requiredGroups = template['ui.requiredGroups'];
  const dynamicAddGroups = template['ui.dynamicAddGroups'];
  return !requiredGroups ||
    dynamicAddGroups ||
    requiredGroups <= productGroups.length
    ? productGroups.map((group) => ({
        id: group.id,
        displayName: group.display_name,
        ref: group.ref,
      }))
    : buildGroupsMock(requiredGroups);
}

function buildGroupsMock(amount: number): TGroupReplacement[] {
  const groups = [];
  for (let i = 1; i < amount + 1; i++) {
    groups.push({
      id: 'group-placeholder-' + i,
      displayName: 'Group ' + i,
      ref: 'group' + i,
    });
  }
  return groups;
}
