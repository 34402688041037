import React from 'react';

import { TPreviewDeviceMeta } from 'src/api/types/paywall.types';
import { TSemverObj } from 'src/utils/parsing';
import styled, { css } from 'styled-components';

import {
  TCarouselSlidesState,
  TComponent,
} from '../../../api/types/paywallTemplate.types';
import { transition } from '../css';
import TemplateComponent from '../TemplateComponent';
import HoverTag from './HoverTag';

type HeaderProps = {
  components: TComponent[];
  zIndex: number;
  deviceMeta: TPreviewDeviceMeta;
  inFocusedState?: boolean;
  inFullScreen?: boolean;
  groupId: string | null;
  slides?: TCarouselSlidesState;
  minSDKVersion: TSemverObj;
};

const Wrapper = styled.div<Omit<HeaderProps, 'components'>>`
  display: flex;
  width: 100%;
  z-index: ${({ zIndex }) => zIndex};
  ${({ deviceMeta, inFullScreen }) => css`
    ${applyMarginTop(deviceMeta, inFullScreen)}
  `}
  align-items: center;
  justify-content: center;
  ${transition()};
`;

export default function Header({
  components,
  zIndex,
  deviceMeta,
  inFocusedState,
  inFullScreen,
  groupId,
  slides,
  minSDKVersion,
}: HeaderProps) {
  return (
    <HoverTag
      title={
        components.length && components[0].namiComponentType ? 'Header' : ''
      }
      id={components.length ? components[0].id : 'header'}
    >
      <Wrapper
        zIndex={zIndex}
        deviceMeta={deviceMeta}
        inFocusedState={inFocusedState}
        inFullScreen={inFullScreen}
        groupId={groupId}
        minSDKVersion={minSDKVersion}
      >
        {components.map((component, i) => (
          <TemplateComponent
            key={i}
            component={component}
            inFocusedState={inFocusedState}
            groupId={groupId}
            slides={slides}
            minSDKVersion={minSDKVersion}
          />
        ))}
      </Wrapper>
    </HoverTag>
  );
}

function applyMarginTop(deviceMeta: TPreviewDeviceMeta, fullscreen?: boolean) {
  if (deviceMeta.type === 'phone') {
    if (deviceMeta.os === 'apple' && !fullscreen) {
      return css`
        margin-top: 40px;
      `;
    }
  }
  return css``;
}
