import React, { useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Button, Col, Form, Input, Modal, Radio, Row, Switch } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { TProductVariableType } from 'src/api/types/paywallTemplate.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import { toCamelCase } from 'src/utils/string';

import SmartTextTagCloud from '../products/SmartTextTagCloud';

type AddProductFieldModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (label: string, type: TProductVariableType, value: any) => void;
};

type TProductFieldPayload = {
  name: string;
  defaultValue: string;
  type: TProductVariableType;
};

const selector = createSelector(
  [({ paywallBuilder: { paywall } }: RootState) => paywall],
  (paywall) => ({
    currentProductVariables:
      paywall!.template['ui.productSettings']?.variablesList,
  })
);

export default function AddProductFieldModal({
  isOpen,
  onClose,
  onSubmit,
}: AddProductFieldModalProps) {
  const [form] = Form.useForm<TProductFieldPayload>();
  const { currentProductVariables } = useAppSelector(selector);
  const [selectedType, setSelectedType] =
    useState<TProductVariableType>('string');

  function uniqueFieldName(newName: string): boolean {
    const nameFormatted = toCamelCase(newName);
    const productNameFormatted = toCamelCase(`product${newName}`);
    const result = (currentProductVariables || []).reduce((prev, key) => {
      if (key.variable.toLowerCase() === nameFormatted.toLowerCase())
        return false;
      if (key.variable.toLowerCase() === productNameFormatted.toLowerCase())
        return false;
      return prev;
    }, true);

    return result;
  }

  return (
    <Modal
      title="Add a New Product Field"
      open={isOpen}
      footer={null}
      centered
      onCancel={onClose}
      zIndex={1005}
      forceRender
      afterClose={() => {
        form.resetFields();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        initialValues={{ type: selectedType }}
      >
        <>
          <Row gutter={16}>
            <Col xs={18}>
              <FormItem
                label="Field Name"
                required
                tooltip="Give this product field a useful name such as 'Subtext' or 'Trial Period Text'"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Provide a value',
                  },
                  () => {
                    return {
                      message:
                        'Please enter a value without special characters',
                      validator: (_, value) => {
                        const isValid = /^[a-zA-Z0-9-_\s]+$/g.test(value);
                        return isValid ? Promise.resolve() : Promise.reject();
                      },
                    };
                  },
                  () => {
                    return {
                      message: 'This Field Name is already in use.',
                      validator: (_, value) => {
                        const isValid = uniqueFieldName(value);
                        return isValid ? Promise.resolve() : Promise.reject();
                      },
                    };
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={18}>
              <FormItem
                label="Field Type"
                required
                tooltip="Use Text Fields for displaying information about the product. Use Yes/No fields for constructing Conditions."
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Provide a value',
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) =>
                    setSelectedType(e.target.value as TProductVariableType)
                  }
                  value={selectedType}
                  optionType="button"
                  options={[
                    {
                      value: 'string',
                      label: 'Text',
                    },
                    {
                      value: 'boolean',
                      label: 'Yes/No',
                    },
                  ]}
                />
              </FormItem>
            </Col>
          </Row>
          {selectedType === 'string' && (
            <Row>
              <Col xs={18}>
                <FormItem
                  label="Default Field Value"
                  required
                  name="defaultValue"
                  tooltip="This value will be used for all newly created products."
                  rules={[
                    {
                      required: true,
                      message: 'Provide a value',
                    },
                  ]}
                >
                  <Input
                    maxLength={100}
                    suffix={
                      <SmartTextTagCloud handleChange={appendTagToInput} />
                    }
                  />
                </FormItem>
              </Col>
            </Row>
          )}
          {selectedType === 'boolean' && (
            <Row>
              <Col xs={18}>
                <FormItem
                  label="Default Field Value"
                  name="defaultValue"
                  tooltip="This value will be used for all newly created products."
                >
                  <Switch />
                </FormItem>
              </Col>
            </Row>
          )}
          <Button
            htmlType="submit"
            type="primary"
            disabled={form.getFieldsError().some(({ errors }) => errors.length)}
            onClick={() => {
              form
                .validateFields()
                .then(() =>
                  onSubmit(
                    form.getFieldValue('name'),
                    form.getFieldValue('type'),
                    form.getFieldValue('defaultValue') || false
                  )
                );
            }}
          >
            Add
          </Button>
        </>
      </Form>
    </Modal>
  );

  function appendTagToInput(tagValue: string) {
    form.setFieldValue(
      'defaultValue',
      `${form.getFieldValue('defaultValue') || ''}${tagValue}`
    );
  }
}
