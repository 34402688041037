import React, { useMemo } from 'react';

import {
  CheckCircleOutline,
  DoNotDisturbOnOutlined,
  ErrorOutlineOutlined,
} from '@mui/icons-material';
import type { RadioChangeEvent } from 'antd';
import { Radio, Tooltip } from 'antd';
import { TProductErrorState } from 'src/api/types/paywall.types';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import styled from 'styled-components';

import type { RootState } from '../../../../redux';

const SmallRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    padding: 0 10px !important;
  }
`;

export default function PaywallProductErrorPicker() {
  const { anySkuUnavailable, anySkuInvalid } = useAppSelector(
    ({ paywallBuilder: { anySkuUnavailable, anySkuInvalid } }: RootState) => ({
      anySkuUnavailable,
      anySkuInvalid,
    })
  );
  const currentState: TProductErrorState = useMemo(() => {
    if (anySkuInvalid) return 'invalid';
    if (anySkuUnavailable) return 'unavailable';
    return 'none';
  }, [anySkuInvalid, anySkuUnavailable]);

  const actions = useActions(PaywallBuilderSlice.actions);

  const pageOptions = [
    {
      label: (
        <CheckCircleOutline
          style={{ fontSize: 14, transform: 'translateY(2px)' }}
        />
      ),
      value: 'none',
    },
    {
      label: (
        <Tooltip title="Preview paywall with invalid product SKUs">
          <ErrorOutlineOutlined
            style={{ fontSize: 14, transform: 'translateY(2px)' }}
          />
        </Tooltip>
      ),
      value: 'invalid',
    },
    {
      label: (
        <Tooltip title="Preview paywall with a bad store connection">
          <DoNotDisturbOnOutlined
            style={{ fontSize: 14, transform: 'translateY(2px)' }}
          />
        </Tooltip>
      ),
      value: 'unavailable',
    },
  ];
  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    changeErrorState(value);
  };

  return (
    <SmallRadioGroup
      optionType="button"
      buttonStyle="outline"
      options={pageOptions}
      onChange={onChange}
      value={currentState}
      size="small"
    />
  );

  function changeErrorState(errorState: TProductErrorState) {
    actions.setProductErrorState(errorState);
  }
}
