import React, { useEffect, useState } from 'react';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, Row, Space, Tooltip } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import { namiMediumGray } from 'src/variables';
import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';

import ColorSquare from '../ColorSquare';
import { usePicker } from './PickerContextWrapper';

const StyledPickerButton = styled(Button)`
  flex-grow: 1;
  font-size: 13px;
  padding: 2px 4px !important;
  height: fit-content !important;
  border: none;
`;

export const StyledInputNumber = styled(InputNumber)`
  border: none;
  .ant-input-number-sm input {
    padding: 0 2px;
  }
  .ant-input-number-group-addon {
    background-color: white;
    padding: 0 3px;
    border: none;
  }
`;

const BorderedInputNumber = styled(InputNumber)`
  .ant-input-number-group-addon {
    background-color: white;
    padding: 0 3px;
  }
`;

const StyledRow = styled(Space)<{ current: boolean }>`
  ${({ current }) => {
    return css`
      background: ${current ? '#f5f8fa' : 'none'};
      padding: 4px;
    `;
  }}
`;

const GradientStops = () => {
  const picker = usePicker();

  if (!picker) return null;
  const { addPointWithoutBar, colors, selectedColor } = picker;

  return (
    <Space direction="vertical" size={2}>
      <Row align="middle" style={{ marginBottom: 6, marginTop: 4 }}>
        <Col span={12}>
          <span style={{ fontWeight: 500 }}>Stops</span>
        </Col>
        <Col span={12} style={{ alignItems: 'right', textAlign: 'right' }}>
          <Tooltip title="Add gradient stop">
            <Button
              type="text"
              icon={
                <PlusOutlined style={{ fontSize: 12, color: namiMediumGray }} />
              }
              onClick={() => addPointWithoutBar()}
              disabled={colors.length >= 10}
            />
          </Tooltip>
        </Col>
      </Row>
      {colors.map((color, index) => {
        return (
          <GradientStop
            left={color.left || 0}
            color={color.value}
            current={index === selectedColor}
            index={index}
          />
        );
      })}
    </Space>
  );
};

export default GradientStops;

const GradientStop = ({
  left,
  color,
  current,
  index,
}: {
  left: number;
  color: string;
  current: boolean;
  index: number;
}) => {
  const picker = usePicker();
  const tinyColor = tinycolor(color);
  const hex = tinyColor.toHex();
  const [disable, setDisable] = useState('');
  const [newHex, setNewHex] = useState(hex);

  useEffect(() => {
    if (disable !== 'hex') {
      setNewHex(hex);
    }
  }, [picker?.tinyColor, disable, hex]);

  const handleHex = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tinyHex = tinycolor(e.target.value);
    setNewHex(e.target.value);
    if (tinyHex.isValid()) {
      let { r, g, b } = tinyHex.toRgb();
      let newColor = `rgba(${r}, ${g}, ${b}, ${tinyColor.getAlpha()})`;
      picker?.handleChange(newColor);
    }
  };

  const handleOpacity = (value: valueType | null) => {
    let { r, g, b } = tinyColor.toRgb();
    if (value)
      picker?.handleChange(
        `rgba(${r}, ${g}, ${b}, ${Math.round(value as number) / 100})`
      );
  };

  const handleStop = (value: number) => {
    if (value < 0 || value > 100) return;

    let { r, g, b } = tinyColor.toRgb();
    picker?.handleGradient(
      `rgba(${r}, ${g}, ${b}, ${tinyColor.getAlpha()})`,
      value
    );
  };

  return (
    <StyledRow
      direction="horizontal"
      onClick={() => picker?.setSelectedColor(index)}
      current={current}
    >
      <ManagedInput
        value={left}
        callback={(newVal: number) => handleStop(newVal)}
        width={56}
        label={'%'}
      />
      <StyledPickerButton>
        <Row gutter={2} align={'middle'}>
          <Col flex={'22px'}>
            <ColorSquare color={color} colorIsGradient={false} />
          </Col>
          <Col flex={'78px'}>
            <Input
              value={newHex}
              size="small"
              style={{ border: 'none' }}
              onChange={(e) => handleHex(e)}
              onFocus={() => setDisable('hex')}
              onBlur={() => setDisable('')}
            />
          </Col>
          <Col flex={'46px'}>
            <Tooltip title={'Opacity'}>
              <StyledInputNumber
                value={Math.round(tinyColor.getAlpha() * 100)}
                size="small"
                addonAfter={'%'}
                min={0}
                max={100}
                controls={false}
                onChange={(value) => handleOpacity(value)}
              />
            </Tooltip>
          </Col>
        </Row>
      </StyledPickerButton>
      <Tooltip title="Delete gradient stop">
        <Button
          type="text"
          icon={
            <MinusOutlined style={{ fontSize: 12, color: namiMediumGray }} />
          }
          disabled={(picker?.colors.length || 2) <= 2}
          size="small"
          onClick={() => picker?.deletePoint(index)}
        />
      </Tooltip>
    </StyledRow>
  );
};

const ManagedInput = ({
  value,
  callback,
  max = 100,
  label,
  width = 45.75,
}: {
  value: number;
  callback: (newNumber: number) => void;
  max?: number;
  label?: string;
  width?: number;
}) => {
  const [temp, setTemp] = useState(value);

  useEffect(() => {
    setTemp(value);
  }, [value]);

  return (
    <Tooltip title="Gradient stop position">
      <BorderedInputNumber
        size="small"
        value={temp}
        onChange={(e: valueType | null) => setTemp(e as number)}
        onPressEnter={() => callback(temp)}
        onBlur={() => callback(temp)}
        min={0}
        max={max}
        controls={false}
        style={{ width: width }}
        addonAfter={label}
      />
    </Tooltip>
  );
};
