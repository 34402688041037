import React, { useCallback, useMemo, useState } from 'react';

import { EditOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { PhotoLibraryOutlined } from '@mui/icons-material';
import { Button, Form, List, Tooltip } from 'antd';
import { ReactSortable } from 'react-sortablejs';
import { TCarouselSlide } from 'src/api/types/paywallTemplate.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import SearchInput from 'src/components/SearchInput';
import styled from 'styled-components';

import {
  useActions,
  useAppSelector,
} from '../../../../../../hooks/redux.hooks';
import PaywallBuilderSlice from '../../../../../../redux/PaywallBuilderSlice';
import { FieldObject } from '../../../utils/formFieldBuilding';
import EditCarouselDataModal from '../../modals/EditCarouselDataModal';

type CarouselSlidesSelectorProps = Omit<FieldObject, 'variable'> & {
  defaultValue?: string | number;
  onChange: (value: any) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
  value: TCarouselSlide[];
};

const StyledListItem = styled(List.Item)`
  padding-left: 12px !important;
  padding-right: 10px !important;
  .ant-list-item-meta-title {
    font-size: 13px;
    font-weight: 500 !important;
  }
`;

const AddSlideButton = styled(Button)`
  width: 90%;
  align-self: center;
  margin-bottom: 6px;

  div:has(> &) {
    display: flex;
    flex-direction: column;
  }
`;

export default function CarouselSlideSelector({
  defaultValue,
  onChange,
  value: slides,
  ...field
}: CarouselSlidesSelectorProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const [filter, setFilter] = useState('');
  const [maxSlides, productGroupSlides, v2Ready] = useAppSelector(
    ({ paywallBuilder }) => {
      const settings = paywallBuilder.paywall?.template['ui.carousels'];
      const v2Ready = paywallBuilder.paywall?.template['ui.v2Ready'];
      if (!(field.carousel && settings)) return [null, false];
      return [
        settings[field.carousel].maxSlides || null,
        settings[field.carousel].productGroupSlides || false,
        v2Ready,
      ];
    }
  );
  const [isEditFieldsModalOpen, setEditFieldsModalOpen] = useState(false);
  const canAddSlide = !productGroupSlides;

  const handleListChange = useCallback(
    (newValue: any[]): void => {
      if (!field.editable) return;
      if (slides.length !== newValue.length) {
        return onChange(newValue);
      }
      for (let i = 0; i < newValue.length; i++) {
        if (slides[i].id === newValue[i].id) continue;
        return onChange(newValue);
      }
    },
    [field.editable, onChange, slides]
  );

  const filteredList = useMemo(() => {
    return slides.filter((slide: TCarouselSlide) => {
      if (!filter) return true;
      return [slide.id, slide.title].some((value) =>
        value.toLowerCase().includes(filter.toLowerCase())
      );
    });
  }, [filter, slides]);

  return (
    <>
      <Form.Item>
        {slides.length > 4 && (
          <SearchInput
            style={{ marginBottom: 15 }}
            placeholder="Search slides"
            value={filter}
            onChange={setFilter}
          />
        )}
        <List bordered>
          <ReactSortable
            list={slides.map((item: TCarouselSlide) => ({ ...item }))}
            style={{ height: '28vh', overflowY: 'scroll' }}
            setList={handleListChange}
          >
            {filteredList.map((item: TCarouselSlide) => (
              <StyledListItem key={item.id}>
                <List.Item.Meta
                  style={{ cursor: 'point' }}
                  avatar={
                    !filter && <MenuOutlined style={{ cursor: 'grab' }} />
                  }
                  title={<span>{getSlideTitle(item)}</span>}
                  description={getSlideDescription(item)}
                />
                <IconActionButton
                  type="text"
                  size="small"
                  icon={<EditOutlined style={{ fontSize: '13px' }} />}
                  onClick={() => {
                    actions.setEditingSlideId(item.id);
                    if (productGroupSlides)
                      actions.setProductGroupId(item['productGroupId']);
                  }}
                >
                  Edit
                </IconActionButton>
              </StyledListItem>
            ))}
          </ReactSortable>
          {canAddSlide && (
            <Tooltip
              title={`Add up to ${maxSlides} slide${
                (maxSlides || 1) > 1 ? 's' : ''
              }`}
              mouseEnterDelay={0.6}
              placement="bottom"
            >
              <AddSlideButton
                icon={<PlusOutlined />}
                type="primary"
                ghost
                onClick={() => actions.addSlide(field.carousel)}
                disabled={maxSlides !== null && slides.length >= maxSlides}
              >
                Add Slide
              </AddSlideButton>
            </Tooltip>
          )}
          {v2Ready && (
            <AddSlideButton
              icon={
                <PhotoLibraryOutlined
                  style={{
                    fontSize: '14px',
                    transform: 'translateY(2px)',
                    marginRight: 6,
                  }}
                />
              }
              style={{ marginTop: 6, marginBottom: 8 }}
              onClick={() => setEditFieldsModalOpen(true)}
            >
              Edit Slide Data
            </AddSlideButton>
          )}
        </List>
      </Form.Item>
      <EditCarouselDataModal
        isOpen={isEditFieldsModalOpen}
        onClose={() => setEditFieldsModalOpen(false)}
      />
    </>
  );

  function getSlideTitle(item: any): string {
    if (item.hasOwnProperty('carouselTitleText')) return item.carouselTitleText;
    if (item.hasOwnProperty('carouselCarouselTitleTextTitleText'))
      return item.carouselCarouselTitleTextTitleText;
    if (item.hasOwnProperty('title')) return item.title;
    return '';
  }

  function getSlideDescription(slide: TCarouselSlide): JSX.Element {
    if (productGroupSlides) {
      return (
        <span style={{ fontSize: 12.5 }}>{slide['productGroupRefId']}</span>
      );
    }
    return <></>;
  }
}
